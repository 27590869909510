define("discourse/plugins/paczki-post-user-pm-button/discourse/initializers/extend-for-paczki-post-user-pm-button", ["exports", "discourse/lib/plugin-api", "discourse/models/composer"], function (_exports, _pluginApi, _composer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "extend-for-paczki-post-user-pm-button",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("0.1", api => {
        // Asegurar que se incluyan los atributos necesarios
        api.includePostAttributes("show_pm_user_button", "username");
        const pmButton = {
          action: "sendPmToUser",
          icon: "far-comment-dots",
          title: "paczki_post_user_pm_button.button.title",
          className: "reply create fade-out pm-button-plug-in"
        };

        // Añadir el botón "Send PM" al menú del post
        api.addPostMenuButton("pm-user", attrs => {
          const currentUser = api.getCurrentUser();

          // Condición para mostrar el botón
          if (attrs.show_pm_user_button &&
          // Verifica que se debe mostrar el botón
          currentUser &&
          // Verifica que haya un usuario autenticado
          currentUser.username &&
          // Asegura que el usuario actual tenga un username válido
          attrs.username !== currentUser.username &&
          // Verifica que no sea el autor del post
          !attrs.topic.isPrivateMessage // No mostrar en temas privados
          ) {
            const buttonConfig = {
              ...pmButton
            };
            if (!attrs.mobileView) {
              buttonConfig.label = "paczki_post_user_pm_button.button.label";
            }
            return buttonConfig;
          }

          // Si no cumple las condiciones, no mostrar nada
          return null;
        });

        // Modificar comportamiento del botón de respuesta predeterminado
        api.addPostMenuButton("reply-2", attrs => {
          if (attrs.canCreatePost) {
            const replyButton = {
              action: "replyToPost",
              title: "post.controls.reply",
              icon: "reply",
              className: "reply create fade-out pm-button-plug-in"
            };
            if (!attrs.mobileView) {
              replyButton.label = "topic.reply.title";
            }
            if (attrs.show_pm_user_button) {
              replyButton.className += " mmn-no-pull";
            }
            return replyButton;
          }
          return null;
        });

        // Acción del botón "Send PM"
        api.attachWidgetAction("post-menu", "sendPmToUser", function () {
          const composer = this.register.lookup("controller:composer");
          composer.open({
            action: _composer.default.PRIVATE_MESSAGE,
            recipients: this.attrs.username,
            archetypeId: "private_message",
            draftKey: "new_private_message",
            reply: `${window.location.protocol}//${window.location.host}${this.attrs.shareUrl}`
          });
        });
      });
    }
  };
});